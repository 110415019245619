<template>
  <div class="reg-form" :style="{ backgroundImage: backImg }">
    <!-- Loading Page -->
    <!-- Actual Content -->
    <div >
      <!-- Header -->
      <b-row class="center-element" style="margin-right: -2px">
        <b-col lg="8" class="header">
          {{ $t('paymentStatus') }}
        </b-col>
      </b-row>

      <!-- Registration Content -->
      <b-row class="center-element">
        <b-col lg="8" style="padding-left: 10; color: black; font-size: large;">
          <b-card class="reg-card">
            <div class="centered-text">
              <!-- Display API data (payment status) -->
              <p>{{ apiData }}</p>

              <!-- Thank you message when payment is done -->
              <p v-if="apiData === $t('paymentIsDone')" class="thank-you-text">
                Thank you for your payment!
              </p>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      id: '',
      prodctionUrl: `${document.location.origin}/#/`,
      apiData: '',
    };
  },
  computed: {
    totalNet() {
      return this.selectedItem.value; // - this.remaining;
    },
    backImg() {
      return `url(${require('@/assets/images/background/RegisterationForm-bg.jpg')})`;
    },

    confirmedImg() {
      return require('@/assets/images/background/confirmed-bg.jpg');
    },

    clientDomain() {
      return document.location.host.split('.')[0];
    },
  },

  watch: {},

  mounted() {
    this.id = this.$route.params.id;
    this.loadData();
  },

  methods: {
    loadData() {
      // Simulate an API call delay
      setTimeout(() => {
        this.create({
          url: `PayRequest/complete?refno=${this.id}`,
        }).then((data) => {
          if (data.value === 1) {
            this.doneAlert({ text: this.$t('paymentInProgress') });
            this.apiData = this.$t('paymentInProgress')
          } else if (data.value === 2) {
            this.apiData = this.$t('paymentIsDone');
            this.doneAlert({ text: this.$t('paymentIsDone') });
          } else {
            this.doneAlert({ text: this.$t('paymentIsFaild'), type: 'error' });
            this.apiData = this.$t('paymentIsFaild')
          }
          localStorage.removeItem('CHECKOUT_ID');
          this.isLoading = false; // Set loading to false once data is fetched
        }).catch(() => {
          this.doneAlert({ text: this.$t('paymentIsFaild'), type: 'error' });
          this.isLoading = false; // Set loading to false even on error
          this.apiData = this.$t('paymentIsFaild')
          localStorage.removeItem('CHECKOUT_ID');
        });
      }, 1000); // Add some delay to simulate loading
    },
  },
};
</script>

<style>
.reg-form {
  min-height: 100vh;
  background-size: 250px;
}
.reg-form legend,
.reg-form a,
.reg-form label,
.reg-form span {
  letter-spacing: 1.3px;
  color: black;
}
.reg-form input,
.reg-form small,
.reg-form textarea,
.reg-form button {
  letter-spacing: 1px;
}
.reg-form input,
.reg-form textarea {
  color: rgb(104, 102, 102);
}
.reg-form .nav-link.active {
  background: #7367f0 !important;
}

.reg-form .nav-link {
  border-color: #7367f0;
  margin: 3px;
}

.reg-form .header {
  background-color: #7367f0;
  padding: 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 33px;
  letter-spacing: 2px;
  text-align: center;
  box-shadow: 0 0 3px 0 #5e7b85;
}

.reg-form .active-step,
.reg-form .nonActive-step {
  height: 40px;
  letter-spacing: 1.2px;
  text-align: center;
  padding: 10px 10px 0;
  border: 1px solid #7367f0;
  border-radius: 5px;
  cursor: default;
}

.reg-form .active-step {
  background-color: #7367f0;
  color: #fff;
}

.reg-form .nonActive-step {
  background-color: #fff;
  color: #7367f0;
}
.reg-form .reg-card {
  box-shadow: 0 0 3px 0 #5e7b85 !important;
}
.reg-form .reg-steps span {
  color: #7367f0;
  width: 100px;
  border: dashed 1px;
}
.reg-form .guardian-hint {
  font-size: 11px;
  color: red;
  display: block;
  margin-bottom: 15px;
  margin-top: -7px;
}

/* in large screens  */
@media (max-width: 1400px) {
  .reg-form {
    background-image: none;
  }
  .reg-form .reg-steps {
    flex-direction: column;
  }
  .reg-form .reg-steps span {
    width: 1px;
    height: 30px;
  }
}
.centered-text {
  text-align: center;
  font-size: 18px;
  color: #333;
}

.thank-you-text {
  font-size: 24px;
  font-weight: bold;
  color: #4CAF50; /* Green color for success */
  margin-top: 20px;
}
.d-inline-button > .col {
  display: flex !important;
}

.wpwl-form {
  direction: rtl !important;
}
</style>
